import React from 'react';
import { Link } from 'gatsby';

import SubTitle from '../Title/SubTitle/SubTitle';

import styles from './CurrentSeason.module.scss';

const CurrentSeason = ({ season, url }) => {
  return (
    <div className={styles.hero}>
      <Link to={url}>
        <SubTitle>Current Season: {season}</SubTitle>
      </Link>
    </div>
  );
};

export default CurrentSeason;
