import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';

import PageLayout from '../components/PageLayout/PageLayout';
import Wrapper from '../components/Wrapper/Wrapper';
import SEO from '../components/seo';
import Title from '../components/Title/Title/Title';
import ChampionshipScoreCard from '../components/ScoreCard/ChampionshipScoreCard/ChampionshipScoreCard';
import CurrentSeason from '../components/CurrentSeason/CurrentSeason';
import SubTitle from '../components/Title/SubTitle/SubTitle';

const IndexPage: React.FunctionComponent<PageProps<DataProps>> = ({
  data,
  pageContext,
}) => {
  const {
    allDrivers: { nodes: drivers },
    allSeasons: { nodes: seasons },
    allTeams: { nodes: teams },
  } = data;
  const { currentSeason } = pageContext;

  const decades = [2010, 2000, 1990, 1980, 1970, 1960, 1950];

  return (
    <PageLayout hasHeaderMargin={true}>
      <SEO title="Seasons" />

      <Wrapper alignment="center" isContainer={true}>
        <Title>Seasons</Title>

        <CurrentSeason
          season={currentSeason}
          url={`/season/${currentSeason}/`}
        />
      </Wrapper>

      {decades.map((decade) => {
        const years = seasons.filter(
          (season) => Math.floor(season.season / 10) * 10 === decade
        );

        return (
          <Wrapper alignment="center" isContainer={true} key={decade}>
            <SubTitle isLowercase={true}>{decade}s</SubTitle>

            <ChampionshipScoreCard drivers={drivers} seasons={years} />
          </Wrapper>
        );
      })}
    </PageLayout>
  );
};

export const query = graphql`
  query {
    allDrivers {
      nodes {
        code
        name
        nationality
        number
        slug
      }
    }
    allTeams {
      nodes {
        name
        nationality
        slug
      }
    }
    allSeasons {
      nodes {
        round
        season
        driver
        team
      }
    }
  }
`;

export default IndexPage;
