import React from 'react';

import BaseScoreCard from './../BaseScoreCard/BaseScoreCard';

import getTeamNameClassName from '../../../utils/getTeamNameClassName';

import lookupBySlug from '../../../utils/lookupBySlug';

const ChampionshipScoreCard = ({ drivers, seasons }) => {
  return (
    <BaseScoreCard
      scores={seasons.map(season => ({
        className: getTeamNameClassName(season.team),
        key: season.season,
        url: `/season/${season.season}/`,
        value: lookupBySlug(season.driver, drivers).code,
      }))}
      wrap={true}
    />
  );
};

export default ChampionshipScoreCard;
