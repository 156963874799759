import React from 'react';

const withStyles = (WrappedComponent, className) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return <WrappedComponent className={className} {...this.props} />;
    }
  };
};

export default withStyles;
