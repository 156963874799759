import React from 'react';
import classNames from 'classnames';

import styles from './Wrapper.module.scss';

type WrapperProps = {
  alignment?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  isContainer?: Boolean;
};

const Wrapper: React.FunctionComponent<WrapperProps> = ({
  alignment,
  children,
  isContainer,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        isContainer ? styles.container : undefined,
        alignment ? styles[alignment] : undefined
      )}
    >
      {children}
    </div>
  );
};

export default Wrapper;
